import get from 'lodash.get';
import { pageTypeConstants as pageTypes } from '../constants/pageTypeConstants';

export const CATEGORY_LEVEL_1 = 'categoryL1';
export const CATEGORY_LEVEL_2 = 'categoryL2';
export const CATEGORY_LEVEL_3 = 'categoryL3';
export const CATEGORY_LEVEL_4 = 'categoryL4';

export const categorySortOrder = [
    CATEGORY_LEVEL_1,
    CATEGORY_LEVEL_2,
    CATEGORY_LEVEL_3,
    CATEGORY_LEVEL_4,
];

export const getCapitalizedFirstLetter = word => {
    if (!word) {
        return '';
    }
    const [firstLetter] = word;
    const restOfLetters = word.slice(1);
    return `${firstLetter.toUpperCase()}${restOfLetters.toLowerCase()}`;
};

export const getSortedDescCategories = categories => {
    const clonedCategories = categories.slice();
    clonedCategories.sort(
        (a, b) => categorySortOrder.indexOf(b.name) - categorySortOrder.indexOf(a.name)
    );
    return clonedCategories;
};

export const getSortedAscCategories = categories => {
    const clonedCategories = categories.slice();
    clonedCategories.sort(
        (a, z) => categorySortOrder.indexOf(a.name) - categorySortOrder.indexOf(z.name)
    );
    return clonedCategories;
};

// Return the deepest-nested category, closest to L4
export const getLowestCategory = categories => {
    const sorted = getSortedDescCategories(categories);
    return sorted[0];
};

export const isCategory = filter => categorySortOrder.includes(filter.name);

export const getCategoryUrlLabel = category => get(category, 'values[0].urlLabel');

export const getCategoryCode = category => get(category, 'values[0].code') || '';

export const getCategoryDisplayName = category => get(category, 'values[0].displayName') || '';

export const getCategories = filters => filters.filter(isCategory);

export const getPageLevel = category =>
    get(category, 'name') ? category.name.replace('category', '') : ''; // strip the category from name so categoryL1 => L1

export const getCategory = (categories, categoryName = CATEGORY_LEVEL_1) =>
    categories.filter(category => categoryName === category.name)[0];

export const getCategoriesDisplayString = categories =>
    categories.map(category => getCategoryDisplayName(category)).join('/');

export const getAvailableL1Categories = (filters = []) => {
    const availableCategories = getCategories(filters);
    return getCategory(availableCategories);
};

export const getBackToCategory = (appliedFilters = []) => {
    const appliedCategories = getCategories(appliedFilters);
    // Should return the second to last category Applied
    return appliedCategories[appliedCategories.length - 2];
};

export const hasAppliedCategories = (appliedFilters = []) => {
    const appliedCategories = getCategories(appliedFilters);
    return !!appliedCategories && !!appliedCategories.length;
};

export const hasSingleAvailableL1Category = (filters = []) => {
    const availableL1Categories = getAvailableL1Categories(filters);
    const numAvailableL1Categories = get(availableL1Categories, 'values') || [];
    return !!availableL1Categories && numAvailableL1Categories.length === 1;
};

export const shouldShowBackToAllCategories = (
    { pageType = '', isAttributePage = false },
    filters
) => {
    return (
        !(pageType === pageTypes.BROWSE || isAttributePage) &&
        !hasSingleAvailableL1Category(filters)
    );
};

export const getCurrentCategory = (appliedFilters = [], filters = []) => {
    const availableCategories = getCategories(filters);
    let currentCategory;

    if (hasAppliedCategories(appliedFilters)) {
        // If categories applied or non-vertical page results set belongs to single l1,
        // current category is deepest category level user can filter on
        currentCategory = availableCategories[availableCategories.length - 1];
    } else {
        // If non-vertical page with no filters has results belonging to multiple l1s,
        // current categories are the l1s
        currentCategory = getAvailableL1Categories(filters);
    }

    return currentCategory;
};

export const getPreviouslyAppliedCategory = (appliedFilters = []) => {
    let previouslyAppliedCategory;

    if (hasAppliedCategories(appliedFilters)) {
        const appliedCategories = getCategories(appliedFilters);
        // If categories applied, previous category is last category level applied
        previouslyAppliedCategory = appliedCategories[appliedCategories.length - 1];
    }

    // If no filters are applied and results set container multiple L1s,
    // there is no previously applied category
    return previouslyAppliedCategory;
};

export const isCreatorCategoryOrSearchPage = ({ uriRef }) => {
    return /^(\/(de|fr|it|es))?\/(art|furniture|fashion|jewelry|search|creators)\//.test(uriRef);
};

export const isSearchOrBuyOrPage = (uriRef = '') =>
    uriRef.includes('/search/') || uriRef.includes('/buy/');
