import { ReactNode } from 'react';
import * as React from 'react';
import { LoadingComponent as DefaultLoadingComponent } from '../../components/global/LoadingComponent';
import { ErrorComponent as DefaultErrorComponent } from '../ErrorComponent/ErrorComponent';

type LoadingComponentType = () => void | ReactNode;
type ErrorComponentType = () => void | ReactNode;

type Props = {
    // should be a lazy loaded component
    importFunc: () => Promise<ReactNode>;
    ErrorComponent: ErrorComponentType;
    LoadingComponent: LoadingComponentType;
    useLoadingComponent?: boolean;
    load?: boolean;
};

type State = {
    Component: ReactNode | null;
    isError: boolean;
    isLoading: boolean;
};

export class ComponentLoader extends React.Component<Props, State> {
    static defaultProps = {
        LoadingComponent: DefaultLoadingComponent,
        ErrorComponent: DefaultErrorComponent,
        useLoadingComponent: true,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            Component: null,
            isError: false,
            isLoading: false,
        };

        this.onLoadSuccess = this.onLoadSuccess.bind(this);
        this.onLoadFailure = this.onLoadFailure.bind(this);
    }

    componentDidMount(): void {
        if (this.props.load) {
            this.loadComponent();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.load && !this.state.Component) {
            this.loadComponent();
        }
    }

    loadComponent(): void {
        this.props.importFunc().then(this.onLoadSuccess).catch(this.onLoadFailure);
        this.setState({
            isLoading: true,
            isError: false,
        });
    }

    onLoadSuccess(moduleExport: ReactNode): void {
        this.setState({
            Component: moduleExport,
            isLoading: false,
            isError: false,
        });
    }

    onLoadFailure(e: Error): void {
        // eslint-disable-next-line no-console
        console.error(e);
        this.setState({
            isLoading: false,
            isError: true,
        });
    }

    render(): ReactNode {
        const { Component, isLoading, isError } = this.state;
        /**
         * We don't want to pass unused props down, so we destruct this.props and just pass componentProps.
         */
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            importFunc,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            load,
            LoadingComponent,
            ErrorComponent,
            useLoadingComponent,
            ...componentProps
        } = this.props;
        if (Component) {
            // @ts-ignore - fix type later
            return <Component {...componentProps} />;
        } else if (isLoading && !!useLoadingComponent) {
            // @ts-ignore - fix type later
            return <LoadingComponent />;
        } else if (isError) {
            // @ts-ignore - fix type later
            return <ErrorComponent />;
        } else {
            return null;
        }
    }
}
